
// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: "urw-din", sans-serif;
$font-family-header: KairosSans;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$primary: #E7F816;
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66D9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$black: #000000;
$white: #ffffff;


$primary-50:                        lighten($primary, 25%) !default;
$primary-100:                       lighten($primary, 20%) !default;
$primary-200:                       lighten($primary, 15%) !default;
$primary-300:                       lighten($primary, 10%) !default;
$primary-400:                       lighten($primary, 5%) !default;
$primary-500:                       $primary !default;
$primary-600:                       darken($primary, 5%) !default;
$primary-700:                       darken($primary, 10%) !default;
$primary-800:                       darken($primary, 15%) !default;
$primary-900:                       darken($primary, 20%) !default;

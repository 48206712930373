.public-player-profile {
    align-items: center;
    flex-direction: column;
    height: 100%;
    min-height: 100vh;
    padding-bottom: 6rem;
    position: relative;
    width: 100%;

    @media (min-width: 1230px) {
        display: flex;
    }

    @media (min-width: 920px) {
        align-items: center;
    }

    &-content-container {
        max-width: 1230px;
    }

    &-header-image {
        height: 10rem;
        object-fit: cover;
        object-position: 0 10%;
        width: 100%;

        @media (min-width: 768px) {
            height: 17.875rem;
        }
    }

    &-header-content {
        align-items: center;
        background: #FCFCFD;
        display: flex;
        flex-direction: column;
        padding: 0 1rem;
        position: relative;
        transition: 200ms linear;
        width: 100%;
        z-index: 1000;

        .user-avatar {
            border: 4px solid #fff;
            border-radius: 50%;
            margin-top: 1rem;

            &-image {
                height: 6rem !important;
                width: 6rem !important;
            }

            @media (min-width: 768px) {
                &-image, .user-avatar-default {
                    height: 10rem !important;
                    width: 10rem !important;
                }

                .initials {
                    font-size: 3rem !important;
                }
            }
        }

        .with-negative-margin {
            margin-top: -2.5rem;

            @media (min-width: 768px) {
                margin-top: -3.5rem;
            }
        }

        .player-name {
            font-size: 1.5rem;
            line-height: 2rem;
            margin: 1.5rem 0.75rem 0.5rem;

            @media (min-width: 768px) {
                font-size: 1.875rem;
                line-height: 2.375rem;
                margin-bottom: 0.25rem;
            }
        }

        .details-container {
            display: none;
            margin-bottom: 2rem;
            width: 100%;

            .details-icon {
                fill: #454545;
                margin: 0 0.75rem;
            }

            .details-row {
                align-items: center;
                display: flex;
                font-size: 0.93rem;
                margin: 0.31rem 0;

                &:last-child {
                    margin-top: 0.5rem;
                }

                .details-data {
                    color: #454545;
                    font-family: 'DINPro', sans-serif;
                    margin-right: 0.1875rem;
                }

                .social-icon {
                    margin-right: 0.75rem;

                    &:hover {
                        background-color: transparent !important;
                    }
                }

                &:hover {
                    background-color: transparent !important;
                }

                .public-instagram {
                    svg {
                        fill: none;
                    }
                }
            }

            @media (min-width: 768px) {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
            }
        }

        .pill-button {
            margin-bottom: 2.1875rem;

            &-inverted {
                svg {
                    transform: rotate(180deg);
                }
            }

            @media (min-width: 768px) {
                display: none;
            }
        }

        .show {
            display: block;
        }

        @media (min-width: 768px) {
            align-items: flex-start;
        }

        @media (max-width: 375px) {
            padding: 0;
        }
    }

    .has-header-image {
        margin-top: -2.5rem;

        @media (min-width: 768px) {
            margin-top: 0;
        }
    }

    .expanded {
        margin-top: 0;
    }

    .section-title {
        font-size: 1.125rem;
        line-height: 1.5rem;
        margin-bottom: 1.375rem;
    }

    &-highlight-video {
        height: 100%;
        margin-top: 1rem;
        padding: 0 1rem;
        position: relative;

        .iframe-container {
            height: 100%;
            position: relative;
            width: 100%;

            .iframe {
                height: 15.475rem;
                opacity: 1;
                width: 100%;

                @media (min-width: 768px) {
                    height: 22rem;
                    width: 52%;
                }
            }
        }

    }

    &-uploads {
        height: 100%;
        margin-top: 1rem;
        padding: 0 1rem;
        position: relative;

        @media (min-width: 768px) {
            .dropdown-navigation {
                display: none;
            }
        }
    }
}
